<template>
    <div>
        <b-modal ref="modal" hide-footer>
            <template v-slot:modal-title>
                <template v-if="ajouter">
                    {{ $t("compta.ajouter_compte_comptable") }}
                </template>

                <template v-if="modifier">
                    {{ $t("compta.modifier_compte_comptable") }}
                </template>
            </template>

            <div v-if="!modal_loaded">
                <LoadingSpinner class="col-12" />
            </div>

            <div v-if="modal_loaded">
                <form @submit.prevent="checkForm">
                    <input type="hidden" id="accountingaccount_id" class="form-control" v-model="form.accountingaccount_id">
                    <div class="row">
                        <div class="col-12" v-if="form_message !== ''">
                            <ErrorAlert :messageI18n="form_message" />
                        </div>

                        <div class="col-12">
                            <div class="form-group">
                                <label for="accountingaccount_label" class="col-form-label">{{ $t("compta.form.label") }} *</label>
                                <input type="text" id="accountingaccount_label" class="form-control" v-model="form.accountingaccount_label" required>
                            </div>
                        </div>

                        <div class="col-12">
                            <div class="form-group">
                                <label for="accountingaccount_number" class="col-form-label">{{ $t("compta.form.numero_compte") }} *</label>
                                <input type="text" id="accountingaccount_number" class="form-control" v-model="form.accountingaccount_number" required>
                            </div>
                        </div>

                        <div class="col-12 row">
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="accountingaccount_vat_cb" class="col-form-label">{{ $t("compta.form.compte_tva") }}</label>
                                    <b-form-checkbox
                                        id="accountingaccount_vat_cb"
                                        v-model="form.accountingaccount_vat_cb"
                                        unchecked-value="false"
                                    >
                                    {{ $t('global.oui') }}
                                    </b-form-checkbox>
                                </div>
                            </div>
                            <div v-if="hasInvoiceSupplierOCR" class="col-md-6">
                                <div class="form-group">
                                    <label for="accountingaccount_supplier" class="col-form-label">{{ $t("compta.form.compta_supplier") }}</label>
                                    <b-form-checkbox
                                        id="accountingaccount_supplier"
                                        v-model="form.accountingaccount_supplier"
                                        :unchecked-value="false"
                                    >
                                    {{ $t('global.oui') }}
                                    </b-form-checkbox>
                                </div>
                            </div>
                        </div>

                        <div class="col-12" v-if="form.accountingaccount_vat_cb === true">
                            <div class="form-group">
                                <label for="accountingaccount_vat" class="col-form-label">{{ $t("compta.form.taux_tva") }} *</label>
                                <e-select
                                    v-model="form.accountingaccount_vat"
                                    id="accountingaccount_vat"
                                    track-by="vat_value"
                                    label="vat_label"
                                    :placeholder="labelTitleVat"
                                    :deselectLabel="deselectLabel"
                                    :selectedLabel="selectedLabel"
                                    :selectLabel="enter_to_select"
                                    :options="vat"
                                    :searchable="true"
                                    :show-labels="false"
                                    :allow-empty="false"
								/>
                            </div>
                        </div>

						<div class="col-12 row">
							<div class="col-md-6">
								<div class="form-group">
									<label for="accountingaccount_debours" class="col-form-label">{{ $t("compta.form.compte_debours") }}</label>
									<b-form-checkbox
										id="accountingaccount_debours"
										v-model="form.accountingaccount_debours"
										:unchecked-value="false"
                                        :state="accountingaccount_debours_status"
									>
									{{ $t('global.oui') }}
									</b-form-checkbox>
								</div>
							</div>

							<div class="col-md-6">
								<div class="form-group">
									<label for="accountingaccount_avf" class="col-form-label">{{ $t("compta.form.compte_avance_de_frais") }}</label>
									<b-form-checkbox
										id="accountingaccount_avf"
										v-model="form.accountingaccount_avf"
										:unchecked-value="false"
                                        :state="accountingaccount_avf_status"
									>
									{{ $t('global.oui') }}
									</b-form-checkbox>
								</div>
							</div>
						</div>
                    </div>
                </form>

                <b-button v-if="ajouter" class="mt-3" block @click.prevent="checkForm">{{ $t("global.ajouter") }}</b-button>
                <b-button v-if="modifier" class="mt-3" block @click.prevent="checkForm">{{ $t("global.modifier") }}</b-button>
            </div>
        </b-modal>
    </div>
</template>

<script type="text/javascript">
    import Navigation from "@/mixins/Navigation.js"
    import Accounting from "@/mixins/Accounting.js"
    import AccountingAccount from "@/mixins/AccountingAccount.js"
    import Invoice from "@/mixins/Invoice.js"
    import Tools from "@/mixins/Tools.js"

    export default {
        name: "AccountingAccountAjout",
        props: ['accountingplan_id'],
        mixins: [Navigation, Accounting, AccountingAccount, Invoice, Tools],
        data () {
            return {
                deselectLabel: this.getTrad("global.press_enter_to_remove"),
                selectedLabel: this.getTrad("global.selected_label"),
                enter_to_select: this.getTrad("global.press_enter_to_select"),
                labelTitleVat: this.getTrad("compta.search_vat"),
                vat: [],
                modal_loaded: false,
                ajouter: false,
                modifier: false,
                accountingaccount_debours_status: null,
                accountingaccount_avf_status: null,
                form_message: "",
                form: {
                    accountingaccount_vat_cb: false
                },
                default_form: {
                    accountingaccount_id: '',
                    accountingaccount_label: '',
                    accountingaccount_number: '',
                    accountingaccount_vat_cb: false,
					accountingaccount_supplier: false,
					accountingaccount_debours: false,
					accountingaccount_avf: false,
                    accountingaccount_vat: {}
                },
                events_tab: {
                    'TableAction::goToAddAccountingAccount': () => {
                        this.addOrEdit()
                    },
                    'TableAction::goToEditAccountingAccount': (params) => {
                        this.addOrEdit(params.accountingaccount_id)
                    }
                }
            }
        },
        mounted() {
            this.init_component()
        },
        methods: {
            async init_component() {
                //
            },
            async addOrEdit(accountingaccount_id) {
                this.form_message = ""
                this.modal_loaded = false
                this.$refs['modal'].show()

                if(accountingaccount_id) {
                    const temp_form = this.deppCloneObj(this.$parent.filtredAccountingAccount).filter(acounting_account => acounting_account.accountingaccount_id == accountingaccount_id)[0]
                    temp_form.accountingaccount_vat_cb = false

                    if(temp_form.vat) {
                        temp_form.accountingaccount_vat = {
							vat_id: temp_form.vat.vat_id,
							vat_label: temp_form.vat.vat_label,
						}
                    }

                    this.form = temp_form
                    this.ajouter = false
                    this.modifier = true

                    if(this.form.accountingaccount_vat) {
                        this.form.accountingaccount_vat_cb = true
                    } 
                    else {
                        this.form.accountingaccount_vat_cb = false
                    }
                } else {
                    this.form = this.deppCloneObj(this.default_form)
                    this.ajouter = true
                    this.modifier = false
                }

                if(this.vat.length == 0) {
                    this.vat = await this.getVat()
                }

                this.modal_loaded = true
            },
            checkForm: async function () {
                this.form_message = ""
                let error = []

                this.accountingaccount_debours_status = null
                this.accountingaccount_avf_status = null

                if(!this.form.accountingaccount_label) {
                    error.push("Erreur nom")
                }

                if(!this.form.accountingaccount_number) {
                    error.push("Erreur number")
                }

                if(this.form.accountingaccount_vat_cb === true) {
                    if(!this.form.accountingaccount_vat || Object.keys(this.form.accountingaccount_vat).length == 0) {
                        error.push("Erreur tva")
                    } else {
                        this.form.accountingaccount_vat = this.form.accountingaccount_vat.vat_id
                    }

                    if(this.form.accountingaccount_debours === true)
                    {
                        error.push("Erreur accounting debour")
                        this.accountingaccount_debours_status = false
                    }

                    if(this.form.accountingaccount_avf === true)
                    {
                        this.accountingaccount_avf_status = false
                        error.push("Erreur accounting avf")
                    }

                } else {
                    this.form.accountingaccount_vat = null
                }


                if(this.form.accountingaccount_debours === true && this.form.accountingaccount_avf === true)
                {
                    error.push("Erreur accounting debour, avf")
                    this.accountingaccount_debours_status = false
                    this.accountingaccount_avf_status = false
                }

                let result = null
                if(error.length == 0) {
                    if(this.form.accountingaccount_id) {
                        result = await this.editAccountingAccount(
                            this.form.accountingaccount_id,
                            this.accountingplan_id,
                            this.form.accountingaccount_vat,
                            this.form.accountingaccount_label,
							this.form.accountingaccount_number,
							this.form.accountingaccount_debours,
							this.form.accountingaccount_avf,
                            this.form.accountingaccount_supplier
                        )
                    } else {
                        result = await this.addAccountingAccount(
                            this.accountingplan_id,
                            this.form.accountingaccount_vat,
                            this.form.accountingaccount_label,
							this.form.accountingaccount_number,
							this.form.accountingaccount_debours,
							this.form.accountingaccount_avf,
                            this.form.accountingaccount_supplier
                        )
                    }

                    if(result) {
                        this.$parent.loadAccountingAccountListe()
                        this.$refs['modal'].hide()
                        this.$emit('submit')
                    } else {
                        this.form_message = "error.LEP"
                    }
                } else {
                    this.form_message = "formulaire.erreur_champs_non_remplis"
                }
            }
        },
        computed: {
            hasInvoiceSupplierOCR() {
                return this.$store.state.userAccess.hasInvoiceSupplierOCR
            },
        },
        components: {
            LoadingSpinner: () => import('GroomyRoot/components/Logos/LoadingSpinner_35'),
            ErrorAlert: () => import('GroomyRoot/components/Alert/ErrorAlert'),
        }
    }
</script>
